<template>
  <v-container fluid grid-list-md>
    <v-row>
      <v-col cols="12" md="3">
        <v-text-field
          v-model="reserva.dtaInicioFormatada"
          :label="$t('label.data_inicio')"
          prepend-icon="event"
          disabled>
        </v-text-field>
      </v-col>
      <v-col cols="12" md="3">
        <v-text-field
          v-model="reserva.dtaFimFormatada"
          :label="$t('label.data_fim')"
          prepend-icon="event"
          disabled>
        </v-text-field>
      </v-col>
      <v-col cols="12" md="3">
        <input-money
          :label="$tc('label.valor', 1)"
          v-model="reserva.valor"
          :disabled="somenteLeitura"
          :rules="[rules.maximoPadrao]"
          @InputMoney_valorAlterado="valor => valorAlterado(valor, reserva.valor)"/>
      </v-col>
      <v-col cols="12" md="3">
        <input-money
          :label="$tc('label.pendente_reserva', 1)"
          v-model="reserva.pendente"
          v-bind:class="{
            'PlanejamentoAcaoFormContaCorrente__valor-negativo': (reserva.pendente < 0)
          }"
          disabled/>
      </v-col>
    </v-row>
    <v-row>
      <conta-corrente-tabela style="width: 100%"
        :contas-correntes="reserva.contas"
        :somente-leitura="somenteLeitura"
        :exibir-saldo="exibirSaldo"
        @ContaCorrenteTabela_atualizaSaldoAjustado="atualizaSaldoAjustado">
      </conta-corrente-tabela>
    </v-row>
  </v-container>
</template>

<script>
import InputMoney from '../../../../shared-components/inputs/InputMoney';
import Reserva from './Reserva';
import ContaCorrenteTabela from './ContaCorrenteTabela';

export default {
  name: 'PlanejamentoAcaoReservaApuracao',
  components: {
    ContaCorrenteTabela,
    InputMoney,
  },
  props: {
    reserva: {
      type: Reserva,
      required: true,
    },
    somenteLeitura: Boolean,
    contasCorrentes: {
      type: Array,
      default: () => ([]),
    },
    exibirSaldo: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      rules: {
        maximoPadrao: (value) => value <= 99999999.99 || this.$t('message.valor_padrao_maximo'),
      },
    };
  },
  watch: {
    contasCorrentes(values) {
      this.reserva.filtrarEPreencherConta(values);
    },
  },
  methods: {
    valorAlterado(valor, valorAnterior) {
      this.$emit('PlanejamentoAcaoReservaApuracao_valorAlterado', parseFloat((valor - valorAnterior).toFixed(2)));
    },
    atualizaSaldoAjustado() {
      this.reserva.ajustarPendente();
      this.$emit('PlanejamentoAcaoReservaApuracao_reservaAlterada');
    },
  },
};
</script>
