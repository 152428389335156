<template>
  <v-col cols="12" md="12" lg="7"  style="text-align:right">
    <slot name="acoes-padrao" />
    <v-btn @click="salvarAntesDeSolicitar()"
     class="mr-3"
      dark
      color="red"
      v-show="ultimoPasso && podeSolicitarAprovacao && podeSolicitarAprovacaoPorFluxoAprovacao"
      :disabled="!permiteSalvar">
      {{ $t('label.solicitar_aprovacao') }}
    </v-btn>
    <v-btn @click="validaConcluir()"
     class="mr-3"
      dark
      color="red"
      v-show="ultimoPasso && podeSolicitarAprovacao && podeConcluir"
      :disabled="!permiteSalvar">
      {{ $t('label.concluir') }}
    </v-btn>
    <v-btn @click="abrirJustificativaAprovar()"
     class="mr-3"
      dark
      color="primary"
      v-show="ultimoPasso && podeAprovar">
      {{ $t('label.aprovar') }}
    </v-btn>
    <v-btn @click="abrirJustificativaReprovar()"
     class="mr-3"
      dark
      color="red"
      v-show="ultimoPasso && podeAprovar">
      {{ $t('label.reprovar') }}
    </v-btn>
    <v-btn @click="abrirJustificativaAnalise()"
     class="mr-3"
      dark
      color="primary"
      v-show="ultimoPasso && podeAprovar">
      {{ $tc('label.enviar_analise', 1) }}
    </v-btn>
    <confirm
      ref="fluxoSemUsuarioClienteDialog"
      :message="msgFluxoSemAprovador"
      :persistent="true"
      @agree="iniciaFluxo"/>
    <confirm
      ref="concluirAcaoSemFluxoDialog"
      :message="$t('message.concluir_acao_sem_fluxo')"
      :persistent="true"
      @agree="concluir"/>
    <planejamento-acao-form-usuario-cliente
      ref="modalUsuarioCliente"
      :title="indModoVarejo ? $tc('title.selecao_aprovador_fornecedor', 1) : $tc('title.selecao_aprovador_cliente', 1)"
      :passoCliente="passoCliente"
      @selecionarUsuarioCliente="selecionaAprovadorPassoClienteFornecedor"/>
    <planejamento-acao-justificativa
      ref="modalJustificativa"
      :acao="acaoEdicao"
      :config="configuracao.configuracao"
      :obrigatorio="justificativaObrigatoria"
      :salvarJustificativa="actionJustificativa"/>
  </v-col>
</template>
<script>
import { mapGetters } from 'vuex';
import PlanejamentoAcaoJustificativa from '@/spa/planejamento-acao/PlanejamentoAcaoJustificativa';
import PlanejamentoAcaoFormUsuarioCliente from './PlanejamentoAcaoFormUsuarioCliente';
import Confirm from '../../../shared-components/vuetify/dialog/Confirm';

export default {
  props: {
    ultimoPasso: Boolean,
    permiteSalvar: Boolean,
    edicao: Boolean,
    acaoEdicao: Object,
    acaoSalvar: {
      type: Function,
      default: () => ({}),
    },
    uploadEvidenciaObrigatorio: {
      type: Boolean,
      default: false,
    },
    indTemEvidencia: {
      type: Boolean,
      default: false,
    },
    configuracao: {
      type: Object,
      required: false,
    },
    validaConcluirAcao: {
      type: Function,
      default: () => ({}),
    },
    acaoConcluir: {
      type: Function,
      default: () => ({}),
    },
  },
  data() {
    return {
      workflowAcaoResource: this.$api.workflowAcao(this.$resource),
      apuracaoAcaoResource: this.$api.apuracaoAcaoEdicao(this.$resource),

      solicitanteFluxo: false,
      passoCliente: {},
      aprovadorFluxo: false,
      justificativaObrigatoria: false,
      actionJustificativa() {},
      msgFluxoSemAprovador: '',
      iniciouSolicitacaoAprovacao: false,
    };
  },
  components: {
    PlanejamentoAcaoFormUsuarioCliente,
    PlanejamentoAcaoJustificativa,
    Confirm,
  },
  computed: {
    ...mapGetters('implantacao', [
      'indModoVarejo',
    ]),
    ...mapGetters('usuario', [
      'usuarioLogado',
    ]),
    isFocoCliente() {
      return this.acaoEdicao && this.acaoEdicao.passoGeral
        && (this.acaoEdicao.passoGeral.cliente || this.acaoEdicao.passoGeral.holding);
    },
    podeSolicitarAprovacao() {
      // return !this.somenteLeitura && this.statusAcordo === 'EM_CADASTRO' && this.edicao
      //    && this.canAccessCRUD && this.workflowHabilitado && this.usuarioSolicitanteFluxo;
      return this.edicao && this.acaoEdicao.passoGeral
        && this.acaoEdicao.passoGeral.status === 'EM_CADASTRO' && this.solicitanteFluxo;
    },
    podeAprovar() {
      return this.acaoEdicao.passoGeral
        && this.acaoEdicao.passoGeral.status === 'AGUARDANDO_APROVACAO' && this.aprovadorFluxo;
    },
    podeConcluir() {
      const fluxoAprovacao = this.configuracao.configuracao ? this.fetchFluxoAprovacao(this.configuracao.configuracao) : null;
      if (!!fluxoAprovacao && !!fluxoAprovacao.estruturasUsuario) {
        return fluxoAprovacao.estruturasUsuario.filter((el) => el.nomEstruturaUsuario === this.usuarioLogado.estruturaUsuario).length > 0;
      }
      return false;
    },
    podeSolicitarAprovacaoPorFluxoAprovacao() {
      const fluxoAprovacao = this.configuracao.configuracao ? this.fetchFluxoAprovacao(this.configuracao.configuracao) : null;
      return !!fluxoAprovacao && (!!fluxoAprovacao.indHabilitaFluxo || !fluxoAprovacao.estruturasUsuario);
    },
  },
  methods: {
    fetchFluxoAprovacao(value) {
      if (!!value.passo4 && value.passo4.fluxoAprovacao) {
        return value.passo4.fluxoAprovacao.find((el) => el.tipoFluxo === 'PLANEJAMENTO');
      }
      return null;
    },
    carregaInformacoesFluxo(idAcao) {
      if (this.acaoEdicao.passoGeral.status === 'EM_CADASTRO') {
        this.verificarUsuarioSolicitante(idAcao);
      } else {
        this.verificarUsuarioAprovador(idAcao);
        this.buscarStatusFluxo(idAcao);
      }
    },
    verificarUsuarioSolicitante(idAcao) {
      if (this.acaoEdicao.passoGeral && this.acaoEdicao.passoGeral.status === 'EM_CADASTRO') {
        this.workflowAcaoResource.solicitante({ idAcao })
          .then((res) => {
            this.solicitanteFluxo = res.data.usuarioHabilitado;
          });
      }
    },
    async salvarAntesDeSolicitar() {
      if (this.uploadEvidenciaObrigatorio && !this.indTemEvidencia) {
        this.$toast(this.$t('label.upload_anexo_passo_1_obrigatorio'));
        return;
      }
      if (this.iniciouSolicitacaoAprovacao) {
        $logger.log('Evitando dupla solicitacao de acao', Date.now());
        return;
      }
      $logger.log('Solicitando aprovacao da acao', Date.now());

      this.iniciouSolicitacaoAprovacao = true;
      try {
        await this.acaoSalvar(true);
        await this.solicitarAprovacao();
      } catch (e) {
        $logger.log('Erro ao salvar acao', e);
        this.iniciouSolicitacaoAprovacao = false;
      }
    },
    solicitarAprovacao() {
      const resource = this.indModoVarejo
        ? this.workflowAcaoResource.buscaPassosFornecedor
        : this.workflowAcaoResource.buscaPassosClientes;
      const { idAcao } = this.$route.params;

      resource({ idAcao })
        .then((response) => {
          const passosCliente = response.data;
          this.iniciouSolicitacaoAprovacao = false;
          if (passosCliente.length === 0) {
            this.iniciaFluxo();
          } else if (passosCliente.length > 1) {
            const msg = this.indModoVarejo ? this.$t('errors.multiplos_passos_fornecedor')
              : this.$t('errors.multiplos_passos_cliente');
            this.$toast(msg);
          } else if (!passosCliente[0].usuarios.length) {
            this.msgFluxoSemAprovador = this.indModoVarejo
              ? this.$t('message.nao_tem_fornecedor_aprovador')
              : this.$t('message.nao_tem_cliente_aprovador');
            setTimeout(() => this.$refs.fluxoSemUsuarioClienteDialog.open());
          } else if (this.isFocoCliente) {
            // Implementado suporte para apenas 1 passo cliente/fornecedor
            const [passoCli] = passosCliente;
            this.passoCliente = passoCli;
            this.$refs.modalUsuarioCliente.open();
          } else {
            this.iniciaFluxo();
          }
        });
    },
    validaConcluir() {
      if (this.uploadEvidenciaObrigatorio && !this.indTemEvidencia) {
        this.$toast(this.$t('label.upload_anexo_passo_1_obrigatorio'));
        return;
      }
      if (this.validaConcluirAcao()) {
        this.$refs.concluirAcaoSemFluxoDialog.open();
      }
    },
    concluir() {
      this.acaoConcluir().then(() => this.$toast(this.$t('message.concluida_acao_sem_fluxo')));
    },
    iniciaFluxo() {
      this.iniciouSolicitacaoAprovacao = true;
      const { idAcao } = this.$route.params;
      this.workflowAcaoResource.iniciaFluxo({ idAcao }, { idAcao })
        .then(() => {
          this.$router.push({ name: 'planejamento-acao' });
          this.$toast(this.$t('message.solicitacao_aprovacao'));
          this.iniciouSolicitacaoAprovacao = false;
        })
        .catch((err) => {
          this.$error(this, err);
          this.iniciouSolicitacaoAprovacao = false;
        });
    },
    selecionaAprovadorPassoClienteFornecedor(idPasso, idUsuario) {
      const { idAcao } = this.$route.params;
      const params = {
        idAcao,
        idPasso,
        idUsuario,
      };
      const resource = this.indModoVarejo
        ? this.workflowAcaoResource.associaUsuarioFornecedorAprovador
        : this.workflowAcaoResource.associaUsuarioClienteAprovador;
      resource(params, params)
        .then(() => this.iniciaFluxo())
        .catch((err) => this.$error(this, err));
    },
    verificarUsuarioAprovador(idAcao) {
      if (this.acaoEdicao.passoGeral && this.acaoEdicao.passoGeral.status === 'AGUARDANDO_APROVACAO') {
        this.workflowAcaoResource.aprovador({ idAcao })
          .then((response) => {
            if (response.data.usuarioHabilitado) {
              this.aprovadorFluxo = true;
            }
          });
      }
    },
    abrirJustificativaAprovar() {
      this.justificativaObrigatoria = false;
      this.actionJustificativa = this.aprovar;
      this.$refs.modalJustificativa.open();
    },
    aprovar(justificativa, valoresMetadados) {
      const { idAcao } = this.$route.params;
      this.workflowAcaoResource.aprovarPasso({ idAcao },
        { observacao: justificativa })
        .then(() => {
          this.$router.push({ name: 'planejamento-acao' });
          this.$toast(this.$t('message.acao_aprovada'));
        })
        .catch((err) => {
          this.$error(this, err);
        });

      if (valoresMetadados && idAcao) {
        const payload = { id: idAcao, desAtributos: valoresMetadados };
        this.apuracaoAcaoResource.atualizarDesAtributos(payload)
          .catch((err) => {
            this.$error(this, err);
          });
      }
    },
    abrirJustificativaReprovar() {
      this.justificativaObrigatoria = true;
      this.actionJustificativa = this.reprovar;
      this.$refs.modalJustificativa.open();
    },
    reprovar(justificativa) {
      const { idAcao } = this.$route.params;
      this.workflowAcaoResource.reprovar({ idAcao },
        { observacao: justificativa })
        .then(() => {
          this.$router.push({ name: 'planejamento-acao' });
          this.$toast(this.$t('message.acao_reprovada'));
        })
        .catch((err) => {
          this.$error(this, err);
        });
    },
    abrirJustificativaAnalise() {
      this.justificativaObrigatoria = true;
      this.actionJustificativa = this.enviarAnalise;
      this.$refs.modalJustificativa.open();
    },
    enviarAnalise(justificativa) {
      const { idAcao } = this.$route.params;
      this.workflowAcaoResource.enviarAnalise({ idAcao },
        { observacao: justificativa })
        .then(() => {
          this.$router.push({ name: 'planejamento-acao' });
          this.$toast(this.$t('message.acao_enviada_analise'));
        })
        .catch((err) => {
          this.$error(this, err);
        });
    },
    buscarStatusFluxo(idAcao) {
      this.workflowAcaoResource.status({ idAcao })
        .then((response) => {
          this.acaoEdicao.fluxo = response.data;
        });
    },
  },
};
</script>
