import PlanejamentoAcaoFormCampos from '@/spa/planejamento-acao/form/PlanejamentoAcaoFormCampos';
import PlanejamentoAcaoFormComportamento from '@/spa/planejamento-acao/form/PlanejamentoAcaoFormComportamento';
import PlanejamentoAcaoFormContaCorrenteWrapper from '@/spa/planejamento-acao/form/PlanejamentoAcaoFormContaCorrenteWrapper';
import PlanejamentoAcaoFormNotificacaoSustentabilidade from '@/spa/planejamento-acao/form/PlanejamentoAcaoFormNotificacaoSustentabilidade';

const habilitaPassoContas = (configuracao, ref) => {
  const { passo1 } = configuracao;
  if (ref.usuarioCliente && !ref.usuarioClientePodeAcessarPassoContas) {
    return false;
  }
  return passo1.investimentos.filter((i) => i.operacao === 'DEBITO').length > 0;
};

const habilitaPassoComportamento = (configuracao) => {
  const { passo1, passo5 } = configuracao;
  return passo1.fluxo.indexOf('APURACAO') >= 0 && !!passo5;
};

const habilitaPassoNotificacao = (configuracao) => {
  const { passo1, passo3 } = configuracao;
  const investimento = passo1.investimentos.filter((i) => i.tipoVerba && i.tipoInvestimento && i.tipoVerba === 'SUSTENTABILIDADE'
             && (i.tipoInvestimento === 'Tinta SLOB' || i.tipoInvestimento === 'Tinta LINHA')).length > 0;
  const isTemplate = passo3.template === 'SUST_LOGISTICA' || passo3.template === 'SUST_GALPAO' || passo3.template === 'SUST_INDIRETA';
  return investimento && isTemplate;
};

export default {
  componentes: {
    PlanejamentoAcaoFormCampos,
    PlanejamentoAcaoFormComportamento,
    PlanejamentoAcaoFormContaCorrenteWrapper,
    PlanejamentoAcaoFormNotificacaoSustentabilidade,
  },
  passosHabilitados(ref, configuracao, acao) { // eslint-disable-line
    return {
      geral: {
        habilitado: true,
        label: ref.$tc('label.geral', 1),
        componente: 'planejamento-acao-form-campos',
      },
      comportamento: {
        habilitado: habilitaPassoComportamento(configuracao),
        label: ref.$tc('label.apuracao', 1),
        componente: 'planejamento-acao-form-comportamento',
      },
      contas: {
        habilitado: habilitaPassoContas(configuracao, ref),
        label: ref.$tc('label.conta_corrente', 1),
        componente: 'planejamento-acao-form-conta-corrente-wrapper',
      },
      notificao: {
        habilitado: habilitaPassoNotificacao(configuracao, ref),
        label: 'Notificacao',
        componente: 'planejamento-acao-form-notificacao-sustentabilidade',
      },
    };
  },
};
