import Reserva from './Reserva';

export default {
  props: {
    configuracao: {
      type: Object,
      required: true,
    },
    objetoPassoComportamento: {
      type: Object,
      default: () => ({}),
    },
    objetoPassoGeral: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      reservas: [],
    };
  },
  computed: {
    unicaReserva() {
      return !this.configuracao.configuracao.passo1.fluxo.includes(',APURACAO')
        || (this.objetoPassoComportamento.periodosApuracao
          && this.objetoPassoComportamento.periodosApuracao.length === 1
          && this.objetoPassoComportamento.periodosApuracao[0] === 'UNICA_VEZ');
    },
  },
  methods: {
    montarReservas(cb) {
      this.reservas = [];
      if (this.unicaReserva) {
        this.reservas.push(new Reserva(this.objetoPassoGeral.dtaInicio,
          this.objetoPassoGeral.dtaFim, this.objetoPassoGeral.valor, 1, 'UNICA_VEZ'));
      } else {
        this.objetoPassoComportamento.periodosApuracao.forEach(this.criarReservaPorPeriodo);
      }
      if (cb) {
        cb();
      }
    },
    montarCompetencias(meses, periodo, dtaInicio, dtaFim) {
      const dtaInicioMoment = this.moment(dtaInicio);
      const dtaFimMoment = this.moment(dtaFim);

      const mesesAcao = Math.ceil(dtaFimMoment.diff(dtaInicioMoment, 'months', true)) || 1;
      const qtdCompetencias = Math.ceil(mesesAcao / meses);

      const competencias = [];
      const anoMes = this.moment(dtaInicio, 'YYYY-MM');
      const numeroApuracao = this.reservas.length + 1;

      for (let i = 0; i < qtdCompetencias; i += 1) {
        let dtaInicioCompetencia = anoMes.clone().date(1);
        let dtaFimCompetencia = anoMes.add(meses - 1, 'months').clone().endOf('month');
        if (dtaInicioCompetencia.isBefore(dtaInicioMoment)) {
          dtaInicioCompetencia = dtaInicioMoment;
        }
        if (dtaFimCompetencia.isAfter(dtaFimMoment)) {
          dtaFimCompetencia = dtaFimMoment;
        }
        const ordem = numeroApuracao + i;
        competencias.push(new Reserva(dtaInicioCompetencia.format('YYYY-MM-DD'),
          dtaFimCompetencia.format('YYYY-MM-DD'),
          this.objetoPassoGeral.valor,
          ordem,
          periodo,
          `${this.$tc('label.apuracao', 1)} #${ordem}`));

        anoMes.add(1, 'months');
      }
      return competencias;
    },
    criarReservaPorPeriodo(periodo) {
      let meses = 0;
      switch (periodo) {
        case 'TRIMESTRAL':
          meses = 3;
          break;
        case 'ANUAL':
          meses = 12;
          break;
        default:
          meses = 1;
          break;
      }

      this.reservas.push(...this.montarCompetencias(meses, periodo,
        this.objetoPassoGeral.dtaInicio,
        this.objetoPassoGeral.dtaFim));
    },
  },
};
