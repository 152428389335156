<template>
  <div>
    <div class="mt-2 mb-2 title-float accent--text">
          {{$tc('label.notificacao_email_sust', 1)}}
    </div>
  <v-card>
    <v-card-title>
     <span class="theme--light v-label">  {{$tc('message.info_notificacao_sust', 1)}}</span>
    </v-card-title>
     <v-row class="mx-4 d-flex align-center">
        <v-col cols="2" sm="2" md=2 >
          <v-radio-group v-model="email.tipoEmail" :disabled="!podeNotificar">
            <v-radio :label="$t('label.acao_parceria_sust')" :value="'ACAO_PARCERIA'"></v-radio>
            <v-radio :label="$t('label.acao_trade_sust')" :value="'ACAO_TRADE'"></v-radio>
          </v-radio-group>
        </v-col>
        <v-col cols="8" sm="8" md=8 >
            <v-autocomplete
              :disabled="!podeNotificar"
              id="usuario_notificacao"
              name="usuario_notificacao"
              v-model="email.idsUsuario"
              :items="listaUsuarios"
              :no-data-text="$tc('message.nenhum_registro', 1)"
              item-text="nomUsuario"
              item-value="id"
              :label="`${$tc('label.usuarios_funcionarios', 1)}`"
              :search-input.sync="triggerUsuarios"
              @click.native="buscarUsuarios"
              clearable
              multiple
              cache-items
              >
              <template
                v-slot:selection="data">
                <v-chip
                  :input-value="data.selected"
                  close
                  class="chip--select-multi"
                  @click:close="removeChipSelecao(data.item)">
                  {{ data.item.nomUsuario }}
                </v-chip>
              </template>
            </v-autocomplete>
        </v-col>
         <v-col cols="2" sm="2" md=2 class="" >
          <v-btn
            :disabled="!podeNotificar"
            color="primary"
            id="importacaoLateral-dialog-button-cancel"
            @click.native="enviarEmail()">
            {{ $t('label.enviar_email') }}
          </v-btn>
        </v-col>
      </v-row>
  </v-card>
  <v-card class="mt-6">
    <v-card-title>
       <span class="theme--light v-label">{{$tc('label.historico_notificacoes_sust', 1)}}</span>
    </v-card-title>
    <v-data-table
      class="mt-6"
      :headers="cabecalho"
      :items="emailsEnviados"
      :footer-props="{
        itemsPerPageOptions: [10, 25, 50],
      }"
      :no-data-text="$t('label.tabela_sem_conteudo')"
      dense>
      <template v-slot:item.tipo="{ item }">
          {{ item.tipo === 'ACAO_PARCERIA' ? $t('label.acao_parceria_sust') : $t('label.acao_trade_sust') }}
      </template>
    </v-data-table>
  </v-card>
  </div>
</template>

<script>

import { buscarHistoricoNotificacao, buscarUsuarios } from '../../../common/resources/akzo-planejamento-acao-notificacao';

export default {
  name: 'PlanejamentoAcaoFormNotificacaoSustentabilidade',
  components: {
  },
  props: {
    novaAcao: Boolean,
    somenteLeitura: Boolean,
    acaoEdicao: Object,
    edicao: Boolean,
    tipoAcao: {
      type: Object,
      required: true,
    },
    passosHabilitados: {
      type: Object,
      required: true,
    },
    configuracao: {
      type: Object,
      required: true,
    },
    getObjetoOutroPasso: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      acaoNotificacaoResource: this.$api.akzoPlanejamentoAcaoNotificacao(this.$resource),
      email: {
        tipoEmail: null,
        idsUsuario: [],
      },
      triggerUsuarios: null,
      listaUsuarios: [],
      usuarios: [],
      emailsEnviados: [],
      cabecalho: [
        {
          text: this.$tc('label.dta_envio_email', 1), value: 'dtaEnviado', sortable: false, width: 200,
        },
        {
          text: this.$tc('label.solicitado_por', 1), value: 'nomUsuario', sortable: false, width: 170,
        },
        {
          text: this.$tc('label.tipo_email', 1), value: 'tipo', sortable: false, width: 170,
        },
        {
          text: this.$tc('label.destinatarios_notificacao_sust', 1), value: 'destinatarios', sortable: false,
        },
      ],
    };
  },
  watch: {
    triggerUsuarios(val) {
      if (this.timeoutTrigger) {
        window.clearTimeout(this.timeoutTrigger);
      }
      this.timeoutTrigger = window.setTimeout(() => {
        if (val) this.buscarUsuarios(val);
      }, 500);
    },
  },
  computed: {
    podeNotificar() {
      return this.acaoEdicao && this.acaoEdicao.passoGeral
      && this.acaoEdicao.passoGeral.status && this.acaoEdicao.passoGeral.status === 'EM_CADASTRO';
    },
  },
  methods: {
    removeChipSelecao(item) {
      const selecao = this.usuarios;
      const index = selecao.map((s) => s.id).indexOf(item.id);
      if (index >= 0) selecao.splice(index, 1);
    },
    buscarUsuarios(autocomplete = null) {
      if (typeof autocomplete !== 'string') {
        autocomplete = null;
      }

      const params = { autocomplete };
      buscarUsuarios(params, this.$resource)
        .then((res) => {
          this.listaUsuarios = res.data;
        })
        .catch((err) => {
          this.$toast(this.$t(err));
        });
    },
    enviarEmail() {
      if (this.email.tipo === null || this.email.idsUsuario.length < 1) {
        this.$toast(this.$t('errors.campos_obrigatorios.nao_informados'));
        return;
      }
      const params = {
        idAcao: this.acaoEdicao.id,
        ...this.email,
      };
      this.acaoNotificacaoResource.enviarNotificacao(params)
        .then(() => {
          this.buscarHistorico();
          this.novoEmail();
        }).catch((err) => {
          this.$toast(err.data.error);
        });
    },
    buscarHistorico() {
      const params = {
        idAcao: this.acaoEdicao.id,
      };
      buscarHistoricoNotificacao(params, this.$resource)
        .then((res) => {
          this.emailsEnviados = res.data;
        }).catch((err) => {
          this.$toast(err.data.error);
        });
    },
    novoEmail() {
      this.email = {
        tipoEmail: null,
        idsUsuario: [],
      };
    },
    abrePasso() {},
  },
  mounted() {
    if (this.acaoEdicao.id) {
      this.buscarHistorico();
    }
  },

};
</script>
