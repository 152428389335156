<style>
.PlanejamentoAcaoFormContaCorrente .title-float {
  font-weight: 400;
  font-size: 20px;
}
.PlanejamentoAcaoFormContaCorrente__valor-negativo,
.PlanejamentoAcaoFormContaCorrente__valor-negativo input.v-money {
  color: #ff0000ba;
}
</style>
<template>
  <div class="PlanejamentoAcaoFormContaCorrente">
      <planejamento-acao-form-resumo
        :exibe-fornecedor-pagador="exibeFornecedorPagador"
        :exibe-cliente-pagador="exibeClientePagador"
        :objeto-passo-geral="objetoPassoGeral"
        :ordenacao-formulario="ordenacaoFormulario"
        :somenteLeitura="somenteLeitura"
        ref="resumo">
        <div slot="titulo-acao">
          <slot name="titulo-acao" />
        </div>
      </planejamento-acao-form-resumo>

      <div class="mt-2 mb-2 title-float accent--text">
        {{$tc('label.conta_corrente', 2)}}
      </div>

      <v-card>
        <v-container fluid grid-list-md>
          <v-row>
            <v-col cols="12" md="3">
              <v-select
                id="planejamento-cc-autocomplete-tipoverba"
                :label="`${$t('label.tipo_verba')} *`"
                :items="tiposVerbas"
                item-text="nome"
                item-value="id"
                :append-icon="mostrarIconeVerbaBloqueada()"
                v-model="formulario.idTipoVerba"
                :disabled="somenteLeitura"
                @change="tipoVerbaSelecionado(formulario.idTipoVerba)"
              />
            </v-col>
            <v-col cols="12" md="3">
              <v-autocomplete
                id="planejamento-cc-autocomplete-periodo"
                class="custom-autocomplete"
                multiple
                v-model="formulario.periodos"
                return-object
                item-text="nome"
                item-value="id"
                :items="periodosContas"
                :label="`${$tc('label.periodo', 1)} *`"
                :no-data-text="$tc('message.nenhum_registro', 1)"
                :clearable="true"
                :disabled="somenteLeitura || !formulario.idTipoVerba"
                :placeholder="(somenteLeitura
                 || !formulario.idTipoVerba)
                  ? null
                   : $tc('message.digite_para_pesquisar', 1)">
              </v-autocomplete>
            </v-col>
            <v-col cols="12" md="3" class="d-flex align-center">
              <v-btn @click="buscarContasCorrentes(true)"
                :disabled="!formulario.periodos.length"
                v-if="!somenteLeitura">
                {{$t('label.buscar')}}
              </v-btn>
            </v-col>
          </v-row>
          <div v-if="mostrarFraseVerbaBloqueada && verbaSelecionada.indBloqueioTemp && !novaAcao && acaoEdicao.passoGeral.status === 'EM_CADASTRO'" style="color:#ff0000ba">
            {{ `Não é possível enviar esta ação para aprovação, pois está bloqueado o uso da verba ${verbaSelecionada.nome} no período de ${formataData(eventoCalendario.dataInicio)} até ${formataData(eventoCalendario.dataFim)}. Por favor aguarde o final do bloqueio para solicitar a aprovação ou altere o tipo de verba desta ação para prosseguir.` }}
          </div>
        </v-container>
      </v-card>

      <div v-if="carregando" class="text-xs-center mt-5 accent--text">
        <v-progress-circular :indeterminate="true" />
      </div>

      <planejamento-acao-form-conta-corrente-vinculos
        ref="vinculosCampos"
        :configuracao="configuracao"
        :objeto-passo-geral="objetoPassoGeral"
        @PLANEJAMENTO_ACAO_VINCULOS_TOGGLE="refazBuscaContasCorrentes"
        class="mt-3"
        :somente-leitura="somenteLeitura"/>

      <v-card class="mt-3">
        <v-card-title>
          {{this.$tc('label.distribuicao_valor', 2)}}
        </v-card-title>
        <v-container fluid grid-list-md>
          <v-row>
            <v-col cols="12" md="3">
              <input-money
                :label="$tc('label.valor', 1)"
                v-model="valorDaAcao"
                :disabled="true"/>
            </v-col>
            <v-col cols="12" md="3" v-if="reservas.length > 1">
              <input-money
                :label="$tc('label.valor_total', 1)"
                v-model="valorTotal"
                :disabled="true"/>
            </v-col>
            <v-col cols="12" md="3">
              <input-money
                :label="$t('label.pendente_reserva')"
                v-model="formulario.pendente"
                v-bind:class="{
                  'PlanejamentoAcaoFormContaCorrente__valor-negativo': (formulario.pendente < 0)
                }"
                :disabled="true"/>
            </v-col>
            <v-col cols="12" v-if="reservas.length === 1">
              <conta-corrente-tabela
                :somente-leitura="somenteLeitura"
                :contas-correntes="reservas[0].contas"
                :exibir-saldo="exibirSaldo"
                @ContaCorrenteTabela_atualizaSaldoAjustado="atualizaSaldoAjustado">
              </conta-corrente-tabela>
            </v-col>
          </v-row>
        </v-container>
      </v-card>

      <planejamento-acao-reservas-apuracao
        v-if="reservas.length > 1"
        class="mt-6"
        :reservas="reservas"
        :contas-correntes="contasCorrentes"
        :somente-leitura="somenteLeitura"
        :exibir-saldo="exibirSaldo"
        @PlanejamentoAcaoReservasApuracao_valorTotalAlterado="valorTotalAlterado"
        @PlanejamentoAcaoReservasApuracao_reservaAlterada="reservaAlterada">
      </planejamento-acao-reservas-apuracao>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import InputMoney from '../../../shared-components/inputs/InputMoney';
import { skipLoading } from '../../../common/functions/loading';
import PlanejamentoAcaoFormResumo from './PlanejamentoAcaoFormResumo';
import PlanejamentoAcaoFormContaCorrenteVinculos from './PlanejamentoAcaoFormContaCorrenteVinculos';
import ContaCorrenteTabela from './conta-corrente/ContaCorrenteTabela';
import PlanejamentoAcaoReservasApuracao from './conta-corrente/PlanejamentoAcaoReservasApuracao';
import PlanejamentoAcaoFormContaCorrenteApuracao from './conta-corrente/PlanejamentoAcaoFormContaCorrenteApuracao';
import ContaCorrenteRedistribuicaoMixin from './conta-corrente/ContaCorrenteRedistribuicaoMixin';
import Reserva from './conta-corrente/Reserva';

import { buscarContasCorrentesElegiveis, obterCalendarioPorMnemonico } from '../../../common/resources/planejamento/planejamento-acao-cadastro';
import AcaoComponenteEvento from './passos-dinamicos/AcaoComponenteEvento';

export default {
  props: {
    novaAcao: Boolean,
    somenteLeitura: Boolean,
    acaoEdicao: Object,
    tipoAcao: {
      type: Object,
      required: true,
    },
  },
  mixins: [PlanejamentoAcaoFormContaCorrenteApuracao, ContaCorrenteRedistribuicaoMixin],
  components: {
    PlanejamentoAcaoReservasApuracao,
    ContaCorrenteTabela,
    PlanejamentoAcaoFormResumo,
    PlanejamentoAcaoFormContaCorrenteVinculos,
    InputMoney,
  },
  computed: {
    ...mapGetters('acaoSaldoContaCorrente', [
      'existeContaSaldoNegativo',
      'contasComSaldoNegativo',
    ]),
    exibeClientePagador() {
      return this.configuracao.habilitaClientePagador && this.objetoPassoGeral.focoPagamento === 'CLIENTE';
    },
    exibeFornecedorPagador() {
      return this.configuracao.habilitaFornecedorPagador && this.objetoPassoGeral.focoPagamento === 'FORNECEDOR';
    },
    ordenacaoFormulario() {
      const { relacaoCampos } = this.configuracao;
      return relacaoCampos;
    },
    verbaReservada() {
      return this.acaoEdicao && this.acaoEdicao.passoGeral
        && this.acaoEdicao.passoGeral.status
        && this.acaoEdicao.passoGeral.status !== 'EM_CADASTRO'
        && this.acaoEdicao.passoGeral.status !== 'EM_ANALISE';
    },
    exibirSaldo() {
      return this.acaoEdicao && this.acaoEdicao.passoGeral
        && this.acaoEdicao.passoGeral.status
        && this.acaoEdicao.passoGeral.status === 'EM_CADASTRO';
    },
  },
  data() {
    return {
      planejamentoAcaoResource: this.$api.planejamentoAcaoCadastro(this.$resource),
      orcamentoTipoVerbaResource: this.$api.orcamentoTipoVerba(this.$resource),
      carregando: false,

      formulario: {
        pendente: 0,
        periodos: [],
        periodosPesquisados: [],
        idTipoVerba: null,
      },
      contasCorrentes: [],
      periodosContas: [],
      tiposVerbas: [],

      erros: {
        valorDistribuido: this.$t('errors.conta_corrente.valor_nao_distribuido'),
        contaSemSaldo: this.$t('errors.conta_corrente.sem_saldo'),
        tipoVerbaBloqueado: this.$t('errors.conta_corrente.verba_bloqueada'),
        campoTipoVerbaObrigatorio: this.$t('errors.conta_corrente.campo_tipo_verba_obrigatorio'),
        campoPeriodosObrigatorio: this.$t('errors.conta_corrente.campo_periodos_obrigatorio'),
        holdingBloqueado: (dto) => `Não é possível enviar esta ação para aprovação, pois está bloqueado o uso da verba ${dto.verba} para o ${this.$t('label.holding')} ${dto.nome_holding}.
        Para desbloquear o uso da verba para este ${this.$t('label.holding')} é necessário que as comprovações pendentes há mais de ${dto.prazo} dias sejam realizadas. 
        Ações que estão gerando o bloqueio: ${this.dtoHoldingEstaBloqueada.ids_acoes_verba_bloqueada}`,
        reservaContaUnica: this.$t('errors.conta_corrente.reserva_conta_unica'),
      },
      valorDaAcao: 0,
      valorTotal: 0,
      valorReservado: 0,
      verbaSelecionada: {},
      eventoCalendario: {},
      mostrarFraseVerbaBloqueada: false,
      tipoDeVerbaBloqueadoParaHolding: false,
      dtoHoldingEstaBloqueada: {},
    };
  },
  methods: {
    ...mapActions('acaoSaldoContaCorrente', [
      'registrarContas',
      'setContas',
    ]),
    emitirValorContaAlterado() {
      const evento = new AcaoComponenteEvento('valorContaAlterado');
      this.$emit('PlanejamentoAcaoFormContaCorrente__evento', evento);
    },
    emitirAlteradoContas() {
      const evento = new AcaoComponenteEvento('alteradoContas');
      this.$emit('PlanejamentoAcaoFormContaCorrente__evento', evento);
    },
    onEvent(evento) {
      if (evento.ehEvento('campoProdutoAlterado')
          || evento.ehEvento('periodoApuracaoAlterado')) {
        this.informaAlteracaoContas();
      } else if (evento.ehEvento('campoValorAlterado')) {
        this.informaAlteracaoContasValor();
      } else if (evento.ehEvento('antesIrProximo')) {
        this.validarAntesIrProximo(evento);
      }
    },
    validaSalvar(isSolicitandoAprovacao = false) {
      return this.validacaoCustomizada(isSolicitandoAprovacao);
    },
    preencherObjetoFormatado(acao) {
      acao.passoContas = this.getObjetoFormatado();
    },
    getObjetoPasso() {
      const idsPeriodos = (this.formulario.periodosPesquisados || []).map((p) => p.id);
      const { idTipoVerba } = this.formulario;

      return {
        reservas: this.reservas,
        valorTotal: this.valorTotal,
        valorPendente: this.formulario.pendente,
        idsPeriodos,
        idTipoVerba,
      };
    },
    valorTotalAlterado(valorTotal) {
      this.valorTotal = valorTotal;
      this.formulario.pendente = (this.reservas.length > 1
        ? this.valorTotal
        : this.valorDaAcao) - this.valorReservado;
    },
    reservaAlterada(valorReservado) {
      this.valorReservado = valorReservado;
      this.formulario.pendente = (this.reservas.length > 1
        ? this.valorTotal
        : this.valorDaAcao) - this.valorReservado;
    },
    abrePassoContaCorrente() {
      this.$refs.resumo.montaResumo();
      setTimeout(() => {
        if (!this.reservas.length) {
          this.montarReservas(this.atualizaPendenteReserva);
        }
      });
    },
    redistribuirContas() {
      this.contasCorrentes = [];
      this.registrarContas([]);
      this.reservas = [];
      this.formulario = {
        pendente: 0,
        periodos: [],
        idTipoVerba: null,
      };
      this.valorReservado = 0;
      if (this.tiposVerbas.length === 1) {
        this.formulario.idTipoVerba = this.tiposVerbas[0].id;
        this.buscarPeriodos();
      }
    },
    alterarValor() {
      this.valorDaAcao = this.objetoPassoGeral.valor;
      this.formulario.pendente = (this.reservas.length > 1 ? this.valorTotal : this.valorDaAcao)
        - this.valorReservado;
    },
    validacaoCustomizada(isSolicitandoAprovacao) {
      if (!isSolicitandoAprovacao) {
        return true;
      }

      const valorDistribuido = this.reservas
        .map((r) => r.valorDistribuido || 0)
        .reduce((p, a) => p + a, 0);

      let naoTemValorPendente = false;

      if (this.reservas.length === 1) {
        naoTemValorPendente = this.formulario.pendente === 0;
      } else {
        naoTemValorPendente = this.reservas
          .map((r) => r.pendente || 0)
          .reduce((p, a) => p + a, 0) === 0;
      }

      if (this.configuracao.indHabilitaContaCorrenteUnica && this.verificaReservaVariasContas()) {
        const msgErro = this.erros.reservaContaUnica;
        this.$toast(msgErro);
        return false;
      }

      const contasNegativos = this.validarValorNegativo();

      const valido = valorDistribuido !== 0
        && naoTemValorPendente && !contasNegativos;

      if (!valido) {
        const msgErro = contasNegativos
          ? this.erros.contaSemSaldo : this.erros.valorDistribuido;
        this.$toast(msgErro);
        return false;
      }

      if (this.verbaSelecionada.indBloqueioTemp && this.mostrarIconeVerbaBloqueada()) {
        const msgErro = this.erros.tipoVerbaBloqueado;
        this.$toast(msgErro);
        return false;
      }

      if (this.tipoDeVerbaBloqueadoParaHolding) {
        const msgErro = this.erros.holdingBloqueado(this.dtoHoldingEstaBloqueada);
        this.$toast(this.$t(msgErro), 'botton', 20E3);
        return false;
      }

      if (!this.formulario.idTipoVerba) {
        const msgErro = this.erros.campoTipoVerbaObrigatorio;
        this.$toast(msgErro);
        return false;
      }

      if (!this.formulario.periodos || !this.formulario.periodos.length) {
        const msgErro = this.erros.campoPeriodosObrigatorio;
        this.$toast(msgErro);
        return false;
      }
      return true;
    },
    validarValorNegativo() {
      if (this.existeContaSaldoNegativo) {
        const idConta = this.contasComSaldoNegativo
          .find((id) => this.verificarContaComValorReserva(id));
        return !!idConta;
      }
      return false;
    },
    verificaReservaVariasContas() {
      const qtdContas = this.reservas.map((value) => value.valorDistribuidoUnicaConta()).reduce((item) => item);
      return qtdContas.length > 1;
    },
    verificarContaComValorReserva(idContaCorrente) {
      const reserva = this.reservas.find((r) => r.contaComValorNaReserva(idContaCorrente));
      return !!reserva;
    },
    atualizaSaldoAjustado() {
      this.reservas[0].ajustarPendente();
      this.reservaAlterada(this.reservas.map((r) => r.valorReservado).reduce((a, b) => a + b, 0));
    },
    atualizaPendenteReserva() {
      const valorTotal = this.reservas
        .map((r) => r.valor)
        .reduce((p, a) => p + a, 0);
      this.valorTotalAlterado(valorTotal);
    },
    refazBuscaContasCorrentes() {
      setTimeout(() => this.buscarContasCorrentes(false));
    },
    buscarContasCorrentes(buscaEmEtapas) {
      if (!this.formulario.periodos.length) {
        return;
      }
      skipLoading();
      this.carregando = true;

      const parametros = this.montaParametrosBusca(buscaEmEtapas);

      buscarContasCorrentesElegiveis(parametros, this.$resource)
        .then((res) => {
          this.trataRespostaBusca(res, buscaEmEtapas);
          this.formulario.periodosPesquisados = [...this.formulario.periodos];
          this.carregando = false;
          setTimeout(() => {
            window.scrollTo(0, document.body.scrollHeight);
          }, 500);
        })
        .catch((err) => {
          this.$toast(err.data.error);
          this.carregando = false;
        });
    },
    recuperarExtensoesCarteiras() {
      const idsExtensoesCarteira = this.configuracao.extensoesCarteira
        .map((e) => this.objetoPassoGeral[e.label])
        .filter((e) => !!e)
        .map((e) => e.id);
      if (this.configuracao.unidadeNegocioCarteira && this.objetoPassoGeral.unidadeNegocio
        && this.objetoPassoGeral.unidadeNegocio.id) {
        idsExtensoesCarteira.push(this.objetoPassoGeral.unidadeNegocio.id);
      }
      return idsExtensoesCarteira;
    },
    montaParametrosBusca(buscaEmEtapas) {
      const parametrosVinculos = this.$refs
        .vinculosCampos
        .valoresFiltroSelecionados();
      const idDivisao = this.objetoPassoGeral.divisao.id,
        idUnidadeNegocio = this.objetoPassoGeral.unidadeNegocio.id,
        idsPeriodos = this.formulario.periodos.map((p) => p.id);

      const idHolding = (this.objetoPassoGeral.holding || {}).id;
      const idCliente = (this.objetoPassoGeral.cliente || {}).id;
      const idAcaoConfiguracaoSnapshot = this.configuracao.idConfiguracao;

      const idExtensaoCliente = (this.objetoPassoGeral.extensaoCliente || {}).id;
      const idExtensoesCarteira = this.recuperarExtensoesCarteiras();

      return {
        idDivisao,
        idsPeriodos,
        idUnidadeNegocio,
        idExtensaoCliente,
        idHolding,
        idCliente,
        buscaEmEtapas,
        idExtensoesCarteira,
        idAcaoConfiguracaoSnapshot,
        ...parametrosVinculos,
      };
    },
    trataRespostaBusca(res, buscaEmEtapas) {
      const { removidos, contas } = res.data;

      this.contasCorrentes = [...contas];
      this.registrarContas(this.contasCorrentes);
      this.preencherContaReservaUnica();

      if (buscaEmEtapas) {
        this.$refs
          .vinculosCampos
          .desmarcaCampos(removidos);
      }
    },
    preencherContaReservaUnica() {
      if (this.reservas.length === 1) {
        this.reservas[0].filtrarEPreencherConta(this.contasCorrentes);
      }
    },
    buscarPeriodos() {
      const idDivisao = this.objetoPassoGeral.divisao.id;
      const dataInicio = this.objetoPassoGeral.dtaInicio;
      const dataFim = this.objetoPassoGeral.dtaFim;
      const { idTipoVerba } = this.formulario;
      const idAcaoConfiguracaoSnapshot = this.configuracao.idConfiguracao;
      const idsPeriodos = this.formulario.periodos.map((p) => p.id);
      const idExtensaoCliente = (this.objetoPassoGeral.extensaoCliente || {}).id;
      const idHolding = (this.objetoPassoGeral.holding || {}).id;
      const idCliente = (this.objetoPassoGeral.cliente || {}).id;
      const idExtensoesCarteira = this.recuperarExtensoesCarteiras();

      this.planejamentoAcaoResource
        .buscarPeriodos({
          idDivisao,
          idTipoVerba,
          dataInicio,
          dataFim,
          idsPeriodos,
          idAcaoConfiguracaoSnapshot,
          idExtensaoCliente,
          idHolding,
          idExtensoesCarteira,
          idCliente,
        })
        .then((res) => {
          this.periodosContas = [...res.data];
        })
        .catch((err) => {
          this.$toast(err.data.error);
        });
    },
    tipoVerbaSelecionado(idTipoVerba) {
      this.mostrarFraseVerbaBloqueada = idTipoVerba === this.verbaSelecionada.id;
      this.formulario.periodos = [];
      this.$refs.vinculosCampos.carregaLabelsFiltrosProduto(idTipoVerba);
      this.buscarPeriodos();
    },
    getObjetoFormatado() {
      const filtrosUtilizados = this.$refs
        .vinculosCampos
        .valoresFiltroSelecionados();
      const filtrosAbertos = this.$refs
        .vinculosCampos
        .filtrosAbertos();

      const idsPeriodos = this.formulario.periodosPesquisados
        ? this.formulario.periodosPesquisados.map((p) => p.id) : [];
      const { idTipoVerba } = this.formulario;

      return {
        reservas: this.reservas,
        idsPeriodos,
        idTipoVerba,
        workspace: {
          filtrosUtilizados,
          filtrosAbertos,
        },
      };
    },
    montaObjetoAcao() {
      const { passoContas } = this.acaoEdicao;
      if (passoContas) {
        this.formulario = {
          pendente: 0,
          periodos: [...passoContas.periodos],
          periodosPesquisados: [...passoContas.periodos],
          idTipoVerba: passoContas.tipoVerba.id,
        };

        this.reservas = [];
        this.buscarPeriodos();
        passoContas.reservas.forEach((r) => this.reservas.push(
          new Reserva(r.dtaInicio, r.dtaFim, r.valor,
            r.ordem, r.periodoApuracao,
            `${this.$tc('label.apuracao', 1)} #${r.ordem}`,
            r.contas),
        ));

        this.montarSaldoContas();

        setTimeout(() => {
          this.reservaAlterada(this.reservas
            .map((r) => r.valorReservado)
            .reduce((a, b) => a + b, 0));
          this.atualizaPendenteReserva();
          this.atualizaCamposMarcados();
        });
      }
    },
    formataData(data) {
      return this.moment(data, 'YYYY-MM-DD').format('DD/MM/YYYY');
    },
    montarSaldoContas() {
      const contas = {};
      this.reservas.flatMap((r) => r.contas)
        .forEach((c) => {
          if (this.verbaReservada || !c.valor) {
            contas[c.idContaCorrente] = c.saldo;
          } else if (contas[c.idContaCorrente]) {
            contas[c.idContaCorrente] -= c.valor;
          } else {
            contas[c.idContaCorrente] = c.saldo - c.valor;
          }
        });

      this.setContas(contas);
    },
    atualizaCamposMarcados() {
      const { passoContas } = this.acaoEdicao;
      const { filtrosAbertos, filtrosUtilizados } = passoContas;
      const utilizados = Object.keys(filtrosUtilizados);

      this.$refs
        .vinculosCampos
        .carregaLabelsFiltrosProduto(passoContas.tipoVerba.id);
      this.$refs
        .vinculosCampos
        .marcaCampos(utilizados);
      this.$refs
        .vinculosCampos
        .toggleFiltros(filtrosAbertos);
    },
    carregarTiposVerbas() {
      const { listaIdVerbaInvestimento } = this.configuracao;

      const { idTipoVerba } = this.formulario;
      const param = idTipoVerba ? { idTipoVerba } : {};

      listaIdVerbaInvestimento.forEach((idOrcamentoVerbaInvestimento) => {
        this.orcamentoTipoVerbaResource
          .buscarPorIdVerbaInvestimento({ idOrcamentoVerbaInvestimento, ...param })
          .then(({ data }) => {
            if (data) {
              this.tiposVerbas.push(data);
              if (this.formulario && data.id === this.formulario.idTipoVerba) {
                this.verbaSelecionada = data;
                if (this.verbaSelecionada.indBloqueioTemp) {
                  obterCalendarioPorMnemonico({ mnemonico: 'BLOQUEIO_USO_VERBA' }, this.$resource)
                    .then((response) => {
                      this.eventoCalendario = response.body;
                      this.mostrarFraseVerbaBloqueada = this.formulario
                        .idTipoVerba === this.verbaSelecionada.id
                        && this.eventoCalendario.dataInicio !== undefined;
                    }, (err) => {
                      this.$error(this, err);
                    });
                }
              }
            }
          });
      });
    },
    mostrarIconeVerbaBloqueada() {
      return this.verbaSelecionada.indBloqueioTemp
        && this.mostrarFraseVerbaBloqueada
        && !this.novaAcao
        && this.acaoEdicao.passoGeral.status === 'EM_CADASTRO' ? 'block' : undefined;
    },
    setHoldingBloqueada() {
      const { idAcao } = this.$route.params;
      const { idTipoVerba } = this.formulario;
      if (!idTipoVerba) {
        return;
      }
      this.planejamentoAcaoResource.holdingEstaBloqueada({ idAcao, idTipoVerba })
        .then((res) => {
          this.dtoHoldingEstaBloqueada = res.data;
          this.tipoDeVerbaBloqueadoParaHolding = this.dtoHoldingEstaBloqueada.bloqueado;
        })
        .catch((err) => {
          this.$toast(err.data.error);
        });
    },
  },
  mounted() {
    this.formulario.pendente = this.objetoPassoGeral.valor;
    this.valorDaAcao = this.objetoPassoGeral.valor;
    if (!this.novaAcao) {
      this.montaObjetoAcao();
      this.setHoldingBloqueada();
    }
    this.carregarTiposVerbas();
  },
  beforeDestroy() {
    this.registrarContas([]);
  },
};
</script>
