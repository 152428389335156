<template>
  <div>
    <v-form ref="formRoi"
      lazy-validation
      autocomplete="off">
      <v-row class="wrap">
        <v-col cols="12" sm="6" md="3">
          <v-autocomplete
            id="planejamento-regiao"
            name="planejamento-regiao"
            :label="isFocoRegional ? `${$tc('label.sales_district', 1)}` : `${$tc('label.sales_district', 1)} *`"
            :items="listaRegioes"
            v-model="roi.regiao"
            clearable
            :rules="[rules.requiredFocoDiffRegional]"
            item-text="nomRegiao"
            item-value="id"
            return-object
            :search-input.sync="triggerRegioes"
            :no-data-text="$tc('message.nenhum_registro', 1)"
            :disabled="somenteLeitura || !habilitaBuscaRegiao"
            @click.native="buscarRegioes"
            @input="(regiao) => regiao && buscarCustomerGroup()">
          </v-autocomplete>
        </v-col>

        <v-col cols="12" sm="6" md="3">
          <v-autocomplete
            id="customer-group"
            name="customer-group"
            :label="isFocoRegional ? `${$tc('label.customer_group', 1)}` : `${$tc('label.customer_group', 1)} *`"
            :items="listaCustomerGroup"
            v-model="roi.customerGroup"
            clearable
            :rules="[rules.requiredFocoDiffRegional]"
            item-text="nomExtensao"
            item-value="id"
            return-object
            :search-input.sync="triggerCustomerGroup"
            :no-data-text="$tc('message.nenhum_registro', 1)"
            :disabled="somenteLeitura || !roi.regiao"
            @click.native="() => buscarCustomerGroup()">
            <template slot="selection" slot-scope="data">
              {{ data.item.idExterno }} - {{ data.item.nomExtensao }}
            </template>
            <template slot="item" slot-scope="data">
              {{ data.item.idExterno }} - {{ data.item.nomExtensao }}
            </template>
          </v-autocomplete>
        </v-col>

        <v-col cols="12" sm="6" md="3">
          <v-autocomplete
            id="brand"
            name="brand"
            :label="`${$tc('label.brand', 1)}`"
            :items="listaBrand"
            v-model="roi.brand"
            clearable
            item-text="nomExtensao"
            item-value="id"
            return-object
            @input="alterouValorBrand"
            :search-input.sync="triggerBrand"
            :no-data-text="$tc('message.nenhum_registro', 1)"
            :disabled="somenteLeitura"
            @click.native="() => buscarBrand()">
            <template slot="selection" slot-scope="data">
              {{ data.item.idExterno }} - {{ data.item.nomExtensao }}
            </template>
            <template slot="item" slot-scope="data">
              {{ data.item.idExterno }} - {{ data.item.nomExtensao }}
            </template>
          </v-autocomplete>
        </v-col>

        <v-col cols="12" sm="6" md="3">
          <v-autocomplete
            id="subbrand"
            name="subbrand"
            :label="`${$tc('label.subbrand', 1)}`"
            :items="listaSubbrand"
            v-model="roi.subbrand"
            clearable
            item-text="nomExtensao"
            item-value="id"
            return-object
            @input="alterouValorSubBrand"
            :search-input.sync="triggerSubbrand"
            :no-data-text="$tc('message.nenhum_registro', 1)"
            :disabled="somenteLeitura || !roi.brand"
            @click.native="() => buscarSubBrand()">
            <template slot="selection" slot-scope="data">
              {{ data.item.idExterno }} - {{ data.item.nomExtensao }}
            </template>
            <template slot="item" slot-scope="data">
              {{ data.item.idExterno }} - {{ data.item.nomExtensao }}
            </template>
          </v-autocomplete>
        </v-col>

        <v-col cols="12" sm="6" md="3">
          <v-autocomplete
            id="cluster"
            name="cluster"
            :label="`${$tc('label.cluster', 1)}`"
            :items="listaCluster"
            v-model="roi.cluster"
            clearable
            item-text="nomExtensao"
            item-value="id"
            return-object
            :search-input.sync="triggerCluster"
            :no-data-text="$tc('message.nenhum_registro', 1)"
            :disabled="somenteLeitura || !roi.subbrand"
            @click.native="() => buscarCluster()">
            <template slot="selection" slot-scope="data">
              {{ data.item.idExterno }} - {{ data.item.nomExtensao }}
            </template>
            <template slot="item" slot-scope="data">
              {{ data.item.idExterno }} - {{ data.item.nomExtensao }}
            </template>
          </v-autocomplete>
        </v-col>

        <v-col cols="12" sm="6" md="3">
          <v-autocomplete
            id="prodcategory"
            name="prodcategory"
            :label="`${$tc('label.prodcategory', 1)}`"
            :items="listaProdCategory"
            v-model="roi.prodCategory"
            clearable
            item-text="nomExtensao"
            item-value="id"
            return-object
            :search-input.sync="triggerProdCategory"
            :no-data-text="$tc('message.nenhum_registro', 1)"
            :disabled="somenteLeitura"
            @click.native="() => buscarProdCategory()">
            <template slot="selection" slot-scope="data">
              {{ data.item.idExterno }} - {{ data.item.nomExtensao }}
            </template>
            <template slot="item" slot-scope="data">
              {{ data.item.idExterno }} - {{ data.item.nomExtensao }}
            </template>
          </v-autocomplete>
        </v-col>

        <v-col cols="12" sm="6" md="3">
          <v-autocomplete
            id="haxquality"
            name="haxquality"
            :label="`${$tc('label.haxquality', 1)}`"
            :items="listaHaxQuality"
            v-model="roi.haxQuality"
            clearable
            item-text="nomExtensao"
            item-value="id"
            return-object
            :search-input.sync="triggerHaxQuality"
            :no-data-text="$tc('message.nenhum_registro', 1)"
            :disabled="somenteLeitura"
            @click.native="() => buscarHaxQuality()">
            <template slot="selection" slot-scope="data">
              {{ data.item.idExterno }} - {{ data.item.nomExtensao }}
            </template>
            <template slot="item" slot-scope="data">
              {{ data.item.idExterno }} - {{ data.item.nomExtensao }}
            </template>
          </v-autocomplete>
        </v-col>

        <v-col cols="12" sm="6" md="3">
          <v-autocomplete
            id="planejamento-targetimpact"
            name="planejamento-targetimpact"
            :label="`${$tc('label.targetimpact', 1)} *`"
            :items="listaTargetImpact"
            v-model="roi.targetImpact"
            clearable
            :rules="[rules.required]"
            :no-data-text="$tc('message.nenhum_registro', 1)"
            :disabled="somenteLeitura">
          </v-autocomplete>
        </v-col>
        <v-col cols="12" sm="6" md="3">
          <input-decimal
            :zero="true"
            :negative="false"
            :label="`${$tc('label.roi_volume', 1)} *`"
            v-model="roi.volume"
            :rules="[rules.requiredNumber]"
            :disabled="somenteLeitura"
            class="v-text-field"/>
        </v-col>
        <v-col cols="12" sm="6" md="3">
          <v-menu
            ref="data_inicio"
            :close-on-content-click="false"
            v-model="menuDtaInicio"
            id="planejamento-data-inicio"
            name="planejamento-data-inicio"
            :nudge-right="40"
            :disabled="somenteLeitura"
            transition="scale-transition"
            offset-y
            max-width="290px"
            min-width="290px">
            <template v-slot:activator="{ on }">
              <v-text-field
                v-on="on"
                class="CalendarioForm-data__text"
                :return-masked-value="true"
                v-mask="'##-##-####'"
                :rules="[rules.required, rules.validDate]"
                v-model="dateInicioFormatada"
                :disabled="somenteLeitura"
                :label="`${$t('label.inicio_periodo_retorno')} *`"
                prepend-icon="event">
              </v-text-field>
            </template>

            <v-date-picker
              locale="pt-br"
              color="primary"
              v-model="roi.dataInicioRetorno"
              :disabled="somenteLeitura"
              @input="menuDtaInicio = false">
            </v-date-picker>
          </v-menu>
        </v-col>
        <v-col cols="12" sm="6" md="3">
          <v-menu
            ref="data_fim"
            :close-on-content-click="false"
            v-model="menuDtaFim"
            id="planejamento-data-fim"
            name="planejamento-data-fim"
            :nudge-right="40"
            :disabled="somenteLeitura"
            transition="scale-transition"
            offset-y
            max-width="290px"
            min-width="290px">
            <template v-slot:activator="{ on }">
              <v-text-field
                v-on="on"
                class="CalendarioForm-data__text"
                :return-masked-value="true"
                v-mask="'##-##-####'"
                :rules="[rules.required, rules.validDate, rules.dtaFimMaiorInicio]"
                v-model="dateFimFormatada"
                :disabled="somenteLeitura"
                :label="`${$t('label.fim_periodo_retorno')} *`"
                prepend-icon="event">
              </v-text-field>
            </template>

            <v-date-picker
              locale="pt-br"
              color="primary"
              :min="roi.dataInicioRetorno"
              v-model="roi.dataFimRetorno"
              :disabled="somenteLeitura"
              @input="menuDtaFim = false">
            </v-date-picker>
          </v-menu>
        </v-col>
        <v-col cols="12" sm="6" md="3">
          <v-text-field
            :label="$t('label.roi')"
            :value="getPercent(roi.vlrRoi)"
            filled
            readonly/>
        </v-col>
        <v-col cols="12" sm="6" md="9">
          <v-textarea
            id="planejamento-justificativa"
            name="justificativa-justificativa"
            :label="`${$tc('label.justificativa', 1)}`"
            v-model="roi.justificativa"
            auto-grow
            rows="1"
            counter="300"
            :disabled="somenteLeitura"
            :rules="[rules.maxLength]"
          ></v-textarea>
        </v-col>
        <v-col cols="12" sm="6" md="3">
          <v-btn @click="calcularRoi(true)"
            v-if="!somenteLeitura">
            {{$t('label.calcular')}}
          </v-btn>
        </v-col>
      </v-row>
      <v-alert type="warning"
               v-if="mostrarAlertaRoi"
               elevation="2"
      >
        {{ msgAlertRoi }}
      </v-alert>
    </v-form>
  </div>
</template>
<script>
import { getPercent, copyObject, isEqual } from '../../../produto/common/functions/helpers';
import InputDecimal from '../../../produto/shared-components/inputs/InputDecimal';
import {
  parseDateYYYYMMDD,
  formatDateDDMMYYYY,
} from '../../../produto/common/functions/date-utils';

export default {
  props: {
    somenteLeitura: Boolean,
    acao: Object,
    acaoEdicao: {
      type: Object,
      default: () => {},
    },
    configuracao: {
      type: Object,
      required: true,
    },
  },
  components: {
    InputDecimal,
  },
  data() {
    return {
      planejamentoAcaoResource: this.$api.planejamentoAcaoCadastro(this.$resource),
      roiResource: this.$api.roi(this.$resource),
      clienteResource: this.$api.cliente(this.$resource),
      menuDtaInicio: false,
      menuDtaFim: false,
      isFocoRegional: false,
      mostrarAlertaRoi: false,
      msgAlertRoi: null,

      roi: {
        idRegiao: null,
        targetImpact: null,
        volume: 0,
        justificativa: null,
        dataInicioRetorno: null,
        dataFimRetorno: null,
        vlrRoi: null,
        vlrMargem: null,
      },
      roiBackup: {},

      ordenacaoFormulario: ['customergroup', 'brand', 'subbrand', 'cluster', 'prodcategory', 'haxquality'],

      listaTargetImpact: ['Incremento de volume', 'Recuperação de volume'],
      listaRegioes: [],
      listaCustomerGroup: [],
      listaBrand: [],
      listaSubbrand: [],
      listaCluster: [],
      listaProdCategory: [],
      listaHaxQuality: [],

      triggerRegioes: null,
      triggerCustomerGroup: null,
      triggerBrand: null,
      triggerSubbrand: null,
      triggerCluster: null,
      triggerProdCategory: null,
      triggerHaxQuality: null,

      layoutClass: { wrap: true },
      inputLayout: {
        xs12: true,
        sm6: true,
        md3: true,
      },

      rules: {
        required: (value) => !!value || this.$t('message.campo_obrigatorio'),
        requiredFocoDiffRegional: (value) => (!!value || this.isFocoRegional) || this.$t('message.campo_obrigatorio'),
        requiredNumber: (value) => (!!value || value === 0) || this.$t('message.campo_obrigatorio'),
        maxLength: (value) => ((!!value && value.length <= 300) || !value) || this.$t('message.limite_caracteres_excedido'),
        validDate: (value) => (!value || (value.length === 10 && this.moment(value, 'DD-MM-YYYY').isValid())) || this.$t('message.data_invalida'),
        dtaFimMaiorInicio: () => !this.isDtaFimMaiorInicio() || this.$t('message.data_inicial_maior_final'),
      },
    };
  },
  computed: {
    isEdicao() {
      return !this.novaAcao;
    },
    habilitaBuscaRegiao() {
      return this.acao.divisao && (this.acao.cliente || this.acao.holding || this.acao.regional);
    },
    isRoiCalculado() {
      if (this.roi.vlrRoi == null) {
        return false;
      }
      return true;
    },
    dateInicioFormatada: {
      get() {
        return formatDateDDMMYYYY(this.roi.dataInicioRetorno);
      },
      set(newValue) {
        this.roi.dataInicioRetorno = parseDateYYYYMMDD(newValue);
      },
    },
    dtaInicio: {
      get() {
        return this.roi.dataInicioRetorno;
      },
      set(newValue) {
        this.roi.dataInicioRetorno = newValue;
      },
    },
    dateFimFormatada: {
      get() {
        return formatDateDDMMYYYY(this.roi.dataFimRetorno);
      },
      set(newValue) {
        this.roi.dataFimRetorno = parseDateYYYYMMDD(newValue);
      },
    },
    dtaFim: {
      get() {
        return this.roi.dataFimRetorno;
      },
      set(newValue) {
        this.roi.dataFimRetorno = newValue;
      },
    },
  },
  watch: {
    triggerRegioes(val) {
      if (this.roi && this.roi.idRegiao) {
        const selecao = this.listaRegioes
          .filter((item) => item.id === this.roi.idRegiao);
        if (selecao && selecao.length > 0 && selecao[0].nomRegiao === val) {
          return;
        }
      }

      if (this.timeoutTrigger) {
        window.clearTimeout(this.timeoutTrigger);
      }
      this.timeoutTrigger = window.setTimeout(() => {
        if (val != null) this.buscarRegioes(val);
      }, 500);
    },
    triggerCustomerGroup(val) {
      if (this.roi.idCustomerGroup) {
        const cg = this.listaCustomerGroup.find((e) => e.id === this.roi.idCustomerGroup);
        if (cg.nomExtensao === val) {
          return;
        }
      }

      if (this.timeoutTrigger) {
        window.clearTimeout(this.timeoutTrigger);
      }
      this.timeoutTrigger = window.setTimeout(() => {
        if (val) this.buscarCustomerGroup(val);
      }, 500);
    },
    triggerBrand(val) {
      if (this.roi.idBrand) {
        const cg = this.listaBrand.find((e) => e.id === this.roi.idBrand);
        if (cg.nomExtensao === val) {
          return;
        }
      }

      if (this.timeoutTrigger) {
        this.roi.cluster = null;
        this.roi.subbrand = null;
        window.clearTimeout(this.timeoutTrigger);
      }
      this.timeoutTrigger = window.setTimeout(() => {
        if (val) this.buscarBrand(val);
      }, 500);
    },
    triggerSubbrand(val) {
      if (this.roi.idSubbrand) {
        const cg = this.listaSubbrand.find((e) => e.id === this.roi.idSubbrand);
        if (cg.nomExtensao === val) {
          return;
        }
      }

      if (this.timeoutTrigger) {
        window.clearTimeout(this.timeoutTrigger);
      }
      this.timeoutTrigger = window.setTimeout(() => {
        if (val) this.buscarSubBrand(val);
      }, 500);
    },
    triggerCluster(val) {
      if (this.roi.idCluster) {
        const cg = this.listaCluster.find((e) => e.id === this.roi.idCluster);
        if (cg.nomExtensao === val) {
          return;
        }
      }

      if (this.timeoutTrigger) {
        window.clearTimeout(this.timeoutTrigger);
      }
      this.timeoutTrigger = window.setTimeout(() => {
        if (val) this.buscarCluster(val);
      }, 500);
    },
    triggerHaxQuality(val) {
      if (this.roi.idHaxQuality) {
        const cg = this.listaHaxQuality.find((e) => e.id === this.roi.idHaxQuality);
        if (cg.nomExtensao === val) {
          return;
        }
      }

      if (this.timeoutTrigger) {
        window.clearTimeout(this.timeoutTrigger);
      }
      this.timeoutTrigger = window.setTimeout(() => {
        if (val) this.buscarHaxQuality(val);
      }, 500);
    },
    triggerProdCategory(val) {
      if (this.roi.idProdCategory) {
        const cg = this.listaHaxQuality.find((e) => e.id === this.roi.idProdCategory);
        if (cg.nomExtensao === val) {
          return;
        }
      }

      if (this.timeoutTrigger) {
        window.clearTimeout(this.timeoutTrigger);
      }
      this.timeoutTrigger = window.setTimeout(() => {
        if (val) this.buscarProdCategory(val);
      }, 500);
    },
  },
  methods: {
    getPercent,
    copyObject,
    alterouValorBrand() {
      if (!this.roi.brand) {
        if (this.roi.subbrand) this.roi.subbrand = null;
        if (this.roi.cluster) this.roi.cluster = null;
      }
    },
    alterouValorSubBrand() {
      if (!this.roi.subbrand) {
        if (this.roi.cluster) this.roi.cluster = null;
      }
    },
    isDtaFimMaiorInicio() {
      const dtInicio = this.moment(this.roi.dataInicioRetorno, 'YYYY-MM-DD');
      const dtFinal = this.moment(this.roi.dataFimRetorno, 'YYYY-MM-DD');
      return dtFinal.isBefore(dtInicio);
    },
    calcularRoi(atualizarRoi = false) {
      if (this.roi.volume < 0) {
        this.$toast(this.$t('errors.volume.valor_negativo'));
        return;
      }

      const {
        regiao,
        customerGroup,
        brand,
        subbrand,
        cluster,
        prodCategory,
        haxQuality,
        volume,
      } = this.roi;

      const params = {
        volume,
        vlrAcao: this.acao.valor,
        idAcao: this.acao.idAcao,
      };

      if (regiao) {
        params.idRegiao = regiao.id;
      }
      if (customerGroup) {
        params.codCustomerGroup = customerGroup.idExterno;
      }
      if (brand) {
        params.codBrand = brand.idExterno;
      }
      if (subbrand) {
        params.codSubbrand = subbrand.idExterno;
      }
      if (cluster) {
        params.codCluster = cluster.idExterno;
      }
      if (prodCategory) {
        params.codProdCategory = prodCategory.idExterno;
      }
      if (haxQuality) {
        params.codHaxQuality = haxQuality.idExterno;
      }

      this.roiResource.calcular(params)
        .then((res) => {
          if (atualizarRoi) {
            this.roi.vlrRoi = res.data.vlr_roi_calculado;
          } else {
            this.roi.vlrRoi = res.data.vlr_roi_salvo;
          }

          this.roi.vlrMargem = res.data.vlr_margem;
          this.roiBackup = copyObject(this.roi);
          this.validarCalculoRoi(res.data);
          this.$forceUpdate();
        }).catch((err) => {
          this.$toast(err);
        });
    },
    validarCalculoRoi(res) {
      if (res.vlr_margem === 0) {
        this.mostrarAlertaRoi = false;
        this.$toast(this.$t('message.roi.margem_sem_valor'));
        return;
      }

      let msg = '';
      if (res.vlr_margem < 0) {
        msg = this.$t('message.roi.margem_valor_negativo');
      } else if (res.total_volume >= 0 && res.total_volume <= 5) {
        msg = this.$t('message.roi.margem_valor_baixo');
      }
      if (msg !== '') {
        this.msgAlertRoi = msg;
        this.mostrarAlertaRoi = true;
      } else {
        this.mostrarAlertaRoi = false;
      }
    },
    backupObject() {
      this.roiBackup = copyObject(this.roi);
    },
    setValoresZerados() {
      this.roi = {
        regiao: null,
        targetImpact: null,
        volume: 0,
        justificativa: null,
        dataInicioRetorno: null,
        dataFimRetorno: null,
        vlrRoi: null,
      };
    },
    focoAlterado() {
      this.buscarRegioes();
    },
    setFocoRegional(isFocoRegional) {
      this.isFocoRegional = isFocoRegional;
    },
    buscarRegioes(busca = null) {
      const params = {
        filtro: busca,
        id_divisao: this.acao.divisao.id,
      };
      if (this.acao.cliente) {
        params.id_cliente = this.acao.cliente.id;
      }
      if (this.acao.holding) {
        params.id_holding = this.acao.holding.id;
      }
      if (this.acao.regional) {
        params.id_regional = this.acao.regional.id;
      }
      return this.planejamentoAcaoResource.buscarRegioes(params)
        .then((res) => {
          this.listaRegioes = res.data;
          if (res.data && res.data.length === 1) {
            const [regiao] = res.data;
            this.roi.regiao = regiao;
            this.buscarCustomerGroup();
          }
        })
        .catch((err) => {
          this.$toast(err.data.error);
        });
    },
    buscarCustomerGroup(autocomplete) {
      const params = { autocomplete };
      if (this.acao.cliente) {
        params.idCliente = this.acao.cliente.id;
      }
      if (this.acao.holding) {
        params.idHolding = this.acao.holding.id;
      }
      this.roiResource.buscarCustomerGroup(params).then((res) => {
        this.listaCustomerGroup = [...res.data];
        if (this.listaCustomerGroup.length === 1) {
          const [customerGroup] = this.listaCustomerGroup;
          this.roi.customerGroup = customerGroup;
        }
      });
    },
    buscarBrand(autocomplete) {
      const params = { autocomplete };
      this.roiResource.buscarBrand(params).then((res) => {
        this.listaBrand = [...res.data];
      });
    },
    buscarSubBrand(autocomplete) {
      const params = { autocomplete, idBrand: this.roi.brand.id };
      this.roiResource.buscarSubbrand(params).then((res) => {
        this.listaSubbrand = [...res.data];
      });
    },
    buscarCluster(autocomplete) {
      const params = { autocomplete, idSubbrand: this.roi.subbrand.id };
      this.roiResource.buscarCluster(params).then((res) => {
        this.listaCluster = [...res.data];
      });
    },
    buscarHaxQuality(autocomplete) {
      const params = { autocomplete };
      this.roiResource.buscarHaxQuality(params).then((res) => {
        this.listaHaxQuality = [...res.data];
      });
    },
    buscarProdCategory(autocomplete) {
      const params = { autocomplete };
      this.roiResource.buscarProdCategory(params).then((res) => {
        this.listaProdCategory = [...res.data];
      });
    },
    getAcao() {
      return this.acao;
    },
    setAcaoEdicao() {
      this.roi = {
        ...this.acao.dadosRoi,
      };
      this.roi.dataInicioRetorno = this.moment(this.roi.dataInicioRetorno, 'DD/MM/YYYY hh:mi:ss').format('YYYY-MM-DD');
      this.roi.dataFimRetorno = this.moment(this.roi.dataFimRetorno, 'DD/MM/YYYY hh:mi:ss').format('YYYY-MM-DD');
      this.listaRegioes = this.roi.regiao ? [this.roi.regiao] : [];
      this.listaCustomerGroup = this.roi.customerGroup ? [this.roi.customerGroup] : [];
      this.listaBrand = this.roi.brand ? [this.roi.brand] : [];
      this.listaSubbrand = this.roi.subbrand ? [this.roi.subbrand] : [];
      this.listaCluster = this.roi.cluster ? [this.roi.cluster] : [];
      this.listaProdCategory = this.roi.prodCategory ? [this.roi.prodCategory] : [];
      this.listaHaxQuality = this.roi.haxQuality ? [this.roi.haxQuality] : [];
      this.setFocoRegional(!!this.acao.regional);

      this.backupObject();
      this.calcularRoi();
    },
    validaSecaoRoi(isAcaoSalvar = false) {
      if (!this.$refs.formRoi.validate()) {
        return false;
      }

      if (this.roi.volume < 0) {
        this.$toast(this.$t('errors.volume.valor_negativo'));
        return false;
      }
      if (isAcaoSalvar) {
        if (this.roi.volume != null && !this.isRoiCalculado) {
          this.$toast(this.$t('message.roi.nao_calculado'));
          return false;
        }
        if (!this.isParametrosRoiAlteradosERecalculado()) {
          this.$toast(this.$t('message.roi.valores_alterados_e_nao_recalculado'));
          return false;
        }
      }
      return true;
    },
    validaSolicitacaoSecaoRoi() {
      if ((this.roi.volume != null && !this.isRoiCalculado) || this.roi.vlrMargem === 0
        || this.roi.vlrMargem === undefined) {
        this.$toast(this.$t('message.roi.nao_calculado'));
        return false;
      }
      if (!this.isParametrosRoiAlteradosERecalculado()) {
        this.$toast(this.$t('message.roi.valores_alterados_e_nao_recalculado'));
        return false;
      }
      return true;
    },
    getExtensoes(objetoRoi) {
      if (!objetoRoi) return {};
      const {
        customerGroup,
        brand,
        subbrand,
        cluster,
        prodCategory,
        haxQuality,
      } = objetoRoi;

      const extensoes = {};
      if (customerGroup) {
        extensoes.idCustomerGroup = customerGroup.id;
      }
      if (brand) {
        extensoes.idBrand = brand.id;
      }
      if (subbrand) {
        extensoes.idSubbrand = subbrand.id;
      }
      if (cluster) {
        extensoes.idCluster = cluster.id;
      }
      if (prodCategory) {
        extensoes.idProdCategory = prodCategory.id;
      }
      if (haxQuality) {
        extensoes.idHaxQuality = haxQuality.id;
      }
      return extensoes;
    },
    isParametrosRoiAlteradosERecalculado() {
      if (!isEqual(this.getExtensoes(this.roi), this.getExtensoes(this.roiBackup))) {
        return false;
      }
      const keysIgnoradas = ['targetImpact', 'justificativa', 'dataInicioRetorno', 'dataFimRetorno', 'mapaExtensoesRoi', 'desAtributos'];
      if (!isEqual(this.roi, this.roiBackup, keysIgnoradas)) {
        return false;
      }
      return true;
    },
    getDadosRoi() {
      const dados = {};
      const {
        regiao,
        customerGroup,
        brand,
        subbrand,
        cluster,
        prodCategory,
        haxQuality,
        targetImpact,
        volume,
        justificativa,
        dataInicioRetorno,
        dataFimRetorno,
        vlrRoi,
        vlrMargem,
      } = this.roi;

      dados.vlrRoi = vlrRoi;
      dados.justificativa = justificativa;
      dados.vlrMargem = vlrMargem;

      if (regiao) {
        dados.idRegiao = regiao.id;
      }
      if (customerGroup) {
        dados.idCustomerGroup = customerGroup.id;
      }
      if (brand) {
        dados.idBrand = brand.id;
      }
      if (subbrand) {
        dados.idSubbrand = subbrand.id;
      }
      if (cluster) {
        dados.idCluster = cluster.id;
      }
      if (prodCategory) {
        dados.idProdCategory = prodCategory.id;
      }
      if (haxQuality) {
        dados.idHaxQuality = haxQuality.id;
      }
      if (targetImpact) {
        dados.targetImpact = targetImpact;
      }
      if (volume != null) {
        dados.volume = volume;
      }
      if (dataInicioRetorno) {
        dados.dataInicioRetorno = dataInicioRetorno;
      }
      if (dataFimRetorno) {
        dados.dataFimRetorno = dataFimRetorno;
      }

      return dados;
    },
  },
  mounted() {
    if (!this.isEdicao) {
      this.setValoresZerados();
    }
  },
};
</script>
