<template>
  <v-card v-if="isDetalhesAcao">
    <v-card-title>
      <h2 class="my-2 title-float">{{ $tc('title.detalhe_acao_planejamento_acao', 1)}}</h2>
    </v-card-title>
    <planejamento-acao-form-detalhes-acao
        ref="detalheAcao"
        v-if="isDetalheAcaoTemplate"
        :acao="acao"
        :nova-acao="novaAcao"
        :tipoAcao="tipoAcao"
        @PlanejamentoAcaoFormDetalhesAcao__AlteraValorAcao="alteraValorAcao"
        :somenteLeitura="somenteLeitura"/>
      <planejamento-acao-form-detalhes-acao-customizada
        ref="detalheAcao"
        v-if="isTudoDeCorTemplate"
        :acao="acao"
        :nova-acao="novaAcao"
        :tipoAcao="tipoAcao"
        :configuracao="configuracao"
        @PlanejamentoAcaoFormDetalhesAcao__AlteraValorAcao="alteraValorAcao"
        :somenteLeitura="somenteLeitura"/>
      <planejamento-acao-doacao-direta
        ref="detalheAcao"
        v-if="isDoacaoDiretaLogisticaTemplate"
        :acao="acao"
        :nova-acao="novaAcao"
        :tipoAcao="tipoAcao"
        :configuracao="configuracao"
        @TABELA_EXPORTAR_DADOS="requestExportacao"
        @PlanejamentoAcaoFormDetalhesAcao__AlteraValorAcao="alteraValorAcao"
        :somenteLeitura="somenteLeitura"/>
      <planejamento-acao-doacao-direta-galpao
        ref="detalheAcao"
        v-if="isDoacaoDiretaGalpaoTemplate"
        :acao="acao"
        :nova-acao="novaAcao"
        :tipoAcao="tipoAcao"
        :configuracao="configuracao"
        @PlanejamentoAcaoFormDetalhesAcao__AlteraValorAcao="alteraValorAcao"
        :somenteLeitura="somenteLeitura"/>
      <planejamento-acao-doacao-indireta
        ref="detalheAcao"
        v-if="isDoacaoIndiretaTemplate"
        :acao="acao"
        :nova-acao="novaAcao"
        :tipoAcao="tipoAcao"
        :configuracao="configuracao"
        @PlanejamentoAcaoFormDetalhesAcao__AlteraValorAcao="alteraValorAcao"
        :somenteLeitura="somenteLeitura"/>
      <planejamento-acao-servicos-terceiros
        ref="detalheAcao"
        v-if="isDetalheAcaoTemplateServicosTerceiros"
        :acao="acao"
        :nova-acao="novaAcao"
        :tipoAcao="tipoAcao"
        :configuracao="configuracao"
        @PlanejamentoAcaoFormDetalhesAcao__AlteraValorAcao="alteraValorAcao"
        :somenteLeitura="somenteLeitura"/>
      <planejamento-acao-comunicacao
        ref="detalheAcao"
        v-if="isDetalheAcaoTemplateComunicacao"
        :acao="acao"
        :nova-acao="novaAcao"
        :tipoAcao="tipoAcao"
        :configuracao="configuracao"
        @PlanejamentoAcaoFormDetalhesAcao__AlteraValorAcao="alteraValorAcao"
        :somenteLeitura="somenteLeitura"/>
  </v-card>
</template>

<script>
import PlanejamentoAcaoFormDetalhesAcao from '@/produto/spa/planejamento-acao/form/PlanejamentoAcaoFormDetalhesAcao';
import exportacao from '@/produto/common/functions/exportacao';
import PlanejamentoAcaoFormDetalhesAcaoCustomizada from './PlanejamentoAcaoFormDetalhesAcaoCustomizada';
import PlanejamentoAcaoDoacaoDireta from './detalhe-acao/PlanejamentoAcaoDoacaoDireta';
import PlanejamentoAcaoDoacaoDiretaGalpao from './detalhe-acao/PlanejamentoAcaoDoacaoDiretaGalpao';
import PlanejamentoAcaoDoacaoIndireta from './detalhe-acao/PlanejamentoAcaoDoacaoIndireta';
import PlanejamentoAcaoServicosTerceiros from './detalhe-acao/PlanejamentoAcaoServicosTerceiros';
import PlanejamentoAcaoComunicacao from './detalhe-acao/PlanejamentoAcaoComunicacao';
import { getIndExibeDetalhes } from '../../../common/resources/akzo-planejamento';

export default {
  name: 'PlanejamentoAcaoFormDetalhes',
  components: {
    PlanejamentoAcaoFormDetalhesAcao,
    PlanejamentoAcaoFormDetalhesAcaoCustomizada,
    PlanejamentoAcaoDoacaoDireta,
    PlanejamentoAcaoDoacaoDiretaGalpao,
    PlanejamentoAcaoDoacaoIndireta,
    PlanejamentoAcaoServicosTerceiros,
    PlanejamentoAcaoComunicacao,
  },
  props: {
    acao: {
      type: Object,
      default: {},
    },
    tipoAcao: {
      type: Object,
      required: true,
    },
    somenteLeitura: Boolean,
    novaAcao: Boolean,
    configuracao: {
      type: Object,
      required: true,
    },
  },
  computed: {
    isDetalheAcaoTemplate() {
      return this.configuracao.configuracao.passo3.indCalculoAutomatico
        && this.configuracao.configuracao.passo3.template === 'DETALHE_ACAO';
    },
    isTudoDeCorTemplate() {
      return this.configuracao.configuracao.passo3.indCalculoAutomatico
      && this.configuracao.configuracao.passo3.template === 'SUST_TUDO_DE_COR';
    },
    isDoacaoDiretaLogisticaTemplate() {
      return this.configuracao.configuracao.passo3.indCalculoAutomatico
      && this.configuracao.configuracao.passo3.template === 'SUST_LOGISTICA';
    },
    isDoacaoDiretaGalpaoTemplate() {
      return this.configuracao.configuracao.passo3.indCalculoAutomatico
      && this.configuracao.configuracao.passo3.template === 'SUST_GALPAO';
    },
    isDoacaoIndiretaTemplate() {
      return this.configuracao.configuracao.passo3.indCalculoAutomatico
      && this.configuracao.configuracao.passo3.template === 'SUST_INDIRETA';
    },
    isDetalheAcaoTemplateServicosTerceiros() {
      return this.configuracao.configuracao.passo3.indCalculoAutomatico
        && this.configuracao.configuracao.passo3.template === 'SUST_SERVICO_TERCEIROS';
    },
    isDetalheAcaoTemplateComunicacao() {
      return this.configuracao.configuracao.passo3.indCalculoAutomatico
        && this.configuracao.configuracao.passo3.template === 'SUST_COMUNICACAO';
    },
    isDetalhesAcao() {
      if (this.isDoacaoDiretaGalpaoTemplate || this.isDoacaoIndiretaTemplate
      || this.isDetalheAcaoTemplateServicosTerceiros || this.isDetalheAcaoTemplateComunicacao) {
        return this.indExibeDetalhes;
      }

      return this.isDetalheAcaoTemplate || this.isTudoDeCorTemplate
        || this.isDoacaoDiretaLogisticaTemplate;
    },
  },
  data() {
    return {
      indExibeDetalhes: true,
    };
  },
  methods: {
    alteraValorAcao() {
      this.$emit('PlanejamentoAcaoFormDetalhes__AlteraValorAcao', this.acao.valor);
    },
    detalhesAcoesHabilitados() {
      return this.isDetalhesAcao;
    },
    validaSecaoDetalheAcao() {
      if (this.isDetalhesAcao) {
        return this.$refs.detalheAcao.validaSecaoDetalheAcao();
      }
      return true;
    },
    preencherDetalhes(objeto) {
      if (this.isDetalhesAcao) {
        objeto.acaoServicos = this.$refs.detalheAcao.getServicos();
      }
    },
    requestExportacao(cb) {
      const params = {
        id_acao: this.acao.idAcao,
      };
      exportacao.exportar(cb, 'produto-acao', this, params, 5000, 0);
    },
  },
  beforeMount() {
    getIndExibeDetalhes({}, this.$resource)
      .then((res) => {
        this.indExibeDetalhes = res.data;
      })
      .catch((err) => {
        this.$toast(err.data.error);
      });
  },
};
</script>
